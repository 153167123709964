.uploadWrapper {
  padding: 60px 30px 24px;
  display: flex;
  flex-direction: column;
}

.uploadHeader {
  color: #6b778c;
  font-family: var(--heading-font-family);
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.upload {
  border: 2px dashed #c4cdd5;
  border-radius: 3px;
  width: 100%;
  background-color: #f4f6f8;
}

.uploadBody {
  height: 180px;
  padding: 0 40px;
  background-color: #f4f6f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadImage {
  margin-bottom: 10px;
}

.uploadText {
  color: #172b4d;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.uploadButtons {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.uploadButton + .uploadButton {
  margin-left: 10px;
  width: fit-content;
}

.uploadProgress {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  overflow: hidden;
}

.uploadProgressIcon {
  width: 14px;
  margin-right: 10px;
}

.uploadProgressText {
  display: flex;
  flex-direction: column;
}

.uploadProgressFileName {
  color: #151c2f;
  font-family: var(--heading-font-family);
  font-size: 12px;
  font-weight: 500;
}

.uploadProgressFileDetails {
  color: rgba(107, 119, 140, 0.59);
  font-family: var(--heading-font-family);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
}

.uploadProgressIndicator {
  height: 100%;
  width: 0;
  transition: 300ms;
  background-color: #f5f6f8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-bottom: 2px solid #57d9a3;
}

.uploadProgressSuccessIcon {
  margin-left: auto;
  width: 16px;
}

.uploadStatusCard {
  padding: 20px 10px 20px 20px;
  font-family: "SF UI Text", sans-serif;
  margin: 20px 0;
}

.uploadStatusCardSuccess {
  background-color: #e2ffee;
}

.uploadStatusCardError {
  background-color: #fff0b3;
}

.uploadStatusCardTop {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.uploadStatusCardIcon {
  width: 20px;
  margin-right: 10px;
}

.uploadStatusCardTitle {
  color: #172b4d;
  font-size: 14px;
  font-weight: 600;
}

.uploadStatusCardSection + .uploadStatusCardSection {
  margin-top: 23px;
}

.uploadStatusCardSectionHeader {
  color: #6b778c;
  font-size: 11px;
  margin-bottom: 6px;
}

.uploadStatusCardSectionText {
  color: #172b4d;
  font-size: 14px;
}
