.modal {
  padding: 30px;
  display: flex;
  grid-template-columns: 1fr 0.5fr;
  background-color: #f4f6f8;
  height: 400px;
}

.uploadWrapper {
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.uploadWrapper > span {
  flex-grow: 1;
}

.upload {
  border: 2px dashed #c4cdd5;
  border-radius: 3px;
  width: 100%;
  background-color: #f4f6f8;
}

.uploadBody {
  height: 240px;
  padding: 0 40px;
  background-color: #f4f6f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadImage {
  margin-bottom: 10px;
}

.uploadText {
  color: #172b4d;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.uploadPreview {
  height: 0;
  overflow: hidden;
}

.uploadPreviewActive {
  height: unset;
  flex-grow: 1;
}

.uploadPreviewReset {
  margin-left: auto;
  margin-top: 10px;
  flex-shrink: 0;
}

.splitLine {
  margin: 0 20px;
  flex-shrink: 0;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formButtons {
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
