.modal {
  width: 100%;
  max-width: 750px;
  border-radius: 10px;
  padding-bottom: 0;
  overflow: hidden;
}

.modalBody {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  height: 400px;
}

.modalBodyText {
  height: 100%;
  border-right: 1px solid #dfe1e5;
  font-family: var(--heading-font-family);
  padding-top: 24px;
}

.modalBodyTextHeader {
  color: #212b36;
  font-size: 20px;
  font-weight: bold;
}

.modalBodyTextContent {
  color: #6b778c;
  font-size: 14px;
  font-weight: 300;
}

.modalForm {
  padding: 30px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modalFormRadioButtons {
  margin-bottom: 24px;
}

.modalFormRadioButton {
  height: 36px;
  width: 102px;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  background-color: #ffffff;
  margin-right: 15px;
  outline: none;
  transition: 300ms;
}

.modalFormRadioButtonActive {
  border-color: #2c6894;
}

.modalFormRadioButtonText {
  margin-left: 8px;
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
}

.modalFormInputAddButton {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: none;
  width: fit-content;
}

.modalFormItem {
  margin-bottom: 10px;
}

.modalFormSubmit {
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
}

@media (max-width: 700px) {
  .modal {
    border-radius: 0;
    top: 0;
    margin-top: 0;
  }

  .modalBody {
    display: flex;
    border-radius: 0;
    height: 100vh;
    padding-top: 40px;
    flex-direction: column;
  }

  .modalBodyText {
    height: unset;
    border: none;
  }

  .modalForm {
    padding: 0 0 30px;
  }
}
