.top {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.topText {
  display: flex;
  flex-direction: column;
}

.topBreadCrumb {
  display: flex;
  font-family: var(--heading-font-family);
}

.topBreadCrumbIcon {
  margin-right: 7px;
  width: 7px;
}

.topBreadCrumbText {
  color: #637381;
  font-size: 14px;
  font-weight: 300;
}

.topBreadCrumbHeader {
  color: #212b36;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}

.topButtons {
  display: flex;
}

.topButton {
  margin-left: 20px;
}

.graphCard {
  margin-bottom: 25px;
}

.graphHeader {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.listHeader {
  color: #212b36;
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.listInput {
  font-family: "SF Pro Text", sans-serif;
  margin-bottom: 20px;
}

.listGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
}

.newCenterButton {
  border: 2px dashed #2c6894;
  border-radius: 2px;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  outline: none;
}

.newCenterButtonIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 17px;
  fill: #2c6894;
}

.newCenterButtonText {
  color: #2c6894;
  font-family: var(--heading-font-family);
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .topButtons {
    flex-direction: column;
    align-items: flex-end;
  }

  .topButtons button {
    width: fit-content;
  }

  .topButton {
    margin-top: 10px;
  }
}

@media (max-width: 350px) {
  .topButtons {
    align-items: flex-start;
  }

  .topButton {
    margin-left: 0;
  }
}
