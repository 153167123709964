@font-face {
  src: url("../assets/fonts/CircularStd-Black.ttf");
  font-family: "Circular Std";
}

@font-face {
  src: url("../assets/fonts/CircularStd-Bold.ttf");
  font-family: "Circular Std";
  font-weight: bold;
}

@font-face {
  src: url("../assets/fonts/CircularStd-Medium.ttf");
  font-family: "Circular Std";
  font-weight: 600;
}

@font-face {
  src: url("../assets/fonts/CircularStd-Book.ttf");
  font-family: "Circular Std";
  font-weight: 300;
}

@font-face {
  src: url("../assets/fonts/SFProText-Medium.ttf");
  font-family: "SF Pro Text";
  font-weight: 500;
}

@font-face {
  src: url("../assets/fonts/SF-UI-Text-Regular.ttf");
  font-family: "SF UI text";
}

@font-face {
  src: url("../assets/fonts/SF-UI-Text-Medium.ttf");
  font-family: "SF UI text";
  font-weight: 600;
}

@primary-color: #FF576B;@heading-color: #212B36;@radio-dot-color: #2C6894;