.modal {
  box-shadow: 0 2px 16px 0 rgba(156, 156, 156, 0.15);
  width: 100%;
  max-width: 950px;
  background-color: transparent;
  height: 400px;
}

.modalBody {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  background-color: #f4f6f8;
  min-height: 400px;
}

.modalSide {
  padding-top: 30px;
  background-color: #ffffff;
}

.modalSideHeader {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}

.modalSideButton {
  background-color: transparent;
  border: none;
  border-left: 5px solid transparent;
  padding-left: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto;
  grid-column-gap: 20px;
  transition: 300ms;
  height: 50px;
  width: 100%;
}

.modalSideButtonActive {
  border-color: #2c6894;
  background-color: #f0f9ff;
}

.modalSideButton:hover,
.modalSideButton:focus {
  background-color: #f0f9ff;
  outline: none;
}

.modalSideButtonText {
  color: #637381;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.modalMain {
  display: grid;
  grid-template-columns: 0.48fr 1fr;
}

.modalMainDownload {
  padding: 60px 30px 0 22px;
  border-right: 1px solid #dfe1e5;
  height: 100%;
}

.modalMainDownloadText {
  color: #6b778c;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
}

.modalMainDownloadTextLink {
  color: #2c6894;
  text-decoration: underline;
  font-weight: bold;
  transition: 300ms;
}

.modalMainDownloadTextLink:hover {
  color: #24587b;
}
