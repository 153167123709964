.navBar {
  height: 56px;
  width: 100%;
  background-color: #083a55;
  padding: 0 30px 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.navBarButton {
  background-color: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  outline: none;
}

.navBarButtonIcon {
  width: 20px;
}

.navBarDetails {
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.navBarDetailsAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: auto;
  object-fit: cover;
}

.navBarDetailsName {
  color: #ffffff;
  font-family: "SF Pro Text", sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 8px;
}
