.container {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  min-height: 100vh;
}

.containerSide {
  background-color: #083a55;
  padding: 30px 30px 0 70px;
  overflow: hidden;
}

.containerSideLogo {
  margin-bottom: 200px;
}

.containerSideHeader {
  color: #ffffff;
  font-family: var(--heading-font-family);
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0;
}

.containerSideText {
  color: #c4cdd5;
  font-family: var(--heading-font-family);
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
}

.containerSideTopIcon {
  position: absolute;
  top: 57px;
  right: -45px;
}

.containerSideBottomIcon {
  position: absolute;
  bottom: 84px;
  left: -50px;
}

.containerMain {
  padding: 16% 25% 0;
}

.containerMainHeader {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
}

@media (max-width: 700px) {
  .container {
    grid-template-rows: 80vh 100vh;
    grid-template-columns: 1fr;
    padding-bottom: 40px;
  }

  .containerSide {
    padding-left: 10%;
  }

  .containerSideLogo {
    margin-bottom: 100px;
  }

  .containerMain {
    padding: 60px 10% 0;
  }
}
