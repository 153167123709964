.formInputs {
  display: block;
}

.formLink {
  color: #2c6894;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 20px;
  display: block;
  text-align: right;
}

.formLinkBottom {
  margin-top: 10px;
  text-align: center;
}

.formSubmit {
  width: 100%;
  display: block;
}

.formRedirectText {
  color: #212b36;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  margin-top: 10px;
}

.formRedirectTextLink {
  margin-bottom: 0;
  display: inline;
}

.status {
  margin-bottom: 15px;
}

.successText {
  color: #979797;
  font-family: "Circular Std", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
}

.successTextBold {
  color: #2c6894;
  font-weight: bold;
  text-decoration: underline;
}
