.sideBarLink {
  color: #637381;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  border: none;
  border-left: 5px solid transparent;
  text-decoration: none;
  padding-left: 20px;
  height: 50px;
  background-color: transparent;
}

.sideBarLinkActive {
  border-color: #2c6894;
  background-color: #f0f9ff;
}

.sideBarLinkIcon {
  margin-right: 20px;
  width: 20px;
}

.sideBarLink:hover {
  color: #52626c;
}
