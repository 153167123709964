.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.topText {
  display: flex;
  flex-direction: column;
}

.topBreadCrumb {
  display: flex;
  font-family: var(--heading-font-family);
}

.topBreadCrumbIcon {
  margin-right: 7px;
  width: 7px;
}

.topBreadCrumbText {
  color: #637381;
  font-size: 14px;
  font-weight: 300;
}

.topBreadCrumbHeader {
  color: #212b36;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}

.card {
  padding-left: 0;
  padding-right: 0;
}

.cardFilters {
  display: grid;
  grid-template-columns: 4.7fr 1fr;
  align-items: center;
  margin-bottom: 30px;
}

.cardCount {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 15px;
}

.tableName {
  display: flex;
  align-items: center;
}

.tableNameInitials {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #006fbb;
  color: #ffffff;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-transform: uppercase;
  flex-shrink: 0;
}

.tableNameText {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: bold;
}

.tableDownloadStatus {
  border-radius: 100px;
  color: #3e4e57;
  position: absolute;
  left: 0;
  opacity: 1;
  transition: 300ms;
  top: calc(50% - 11px);
}

.resendCertificate {
  border-color: #2c6894;
  color: #2c6894;
  position: absolute;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 300ms;
  top: calc(50% - 16px);
}

.tableRow:hover .tableDownloadStatus {
  opacity: 0;
}

.tableRow:hover .resendCertificate {
  opacity: 1;
  pointer-events: auto;
}
