@import "~antd/dist/antd.less";
@import "./styles/fonts.css";
@import "./styles/variables.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

body {
  background-color: #f9fbfc;
  padding: 0;
  margin: 0;
}

button,
a {
  cursor: pointer;
}

@primary-color: #FF576B;@heading-color: #212B36;@radio-dot-color: #2C6894;