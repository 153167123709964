.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.topHeader {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0;
}

.input {
  font-family: "SF Pro Text", sans-serif;
  margin-bottom: 20px;
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
}

.newProgramButton {
  border: 2px dashed #ff576b;
  border-radius: 2px;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  outline: none;
}

.newProgramButtonIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 17px;
  fill: var(--primary-color);
}

.newProgramButtonText {
  color: var(--primary-color);
  font-family: var(--heading-font-family);
  font-size: 16px;
  font-weight: 500;
}
