.modal {
  display: flex;
  height: 400px;
}

.modalCertificatePreview {
  flex-basis: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalSplitLine {
  flex-shrink: 0;
  height: 100%;
  margin: 0 40px;
}

.modalControls {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}

.returnButton {
  color: #073a55;
}
