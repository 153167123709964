.tooltip {
  background-color: #212b36;
  padding: 10px 15px;
}

.tooltipHeader {
  color: #ffffff;
  font-family: "SF Pro Text", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
}

.tooltipItems {
  display: grid;
  grid-row-gap: 9px;
}

.tooltipItem {
  display: flex;
  align-items: center;
}

.tooltipItemColor {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.tooltipItemText {
  color: #dfe3e8;
  font-family: var(--heading-font-family);
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.tooltipItemTextId {
  margin-right: 15px;
}
