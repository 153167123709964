.main {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100vh;
}

.mainBody {
  padding: 35px 10% 50px 16%;
}

.mainBodyLogo {
  display: block;
  margin-bottom: 80px;
}

.mainSide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #083a55;
  overflow: hidden;
}

.mainSideImage {
  margin-bottom: 40px;
}

.mainSideText {
  color: #ffffff;
  font-family: var(--heading-font-family);
  font-size: 24px;
  font-weight: bold;
}

.mainSideTopIcon {
  position: absolute;
  top: 39px;
  left: -45px;
}

.mainSideBottomIcon {
  position: absolute;
  bottom: -20px;
  right: -67px;
}

.mainBodyFormWrapper {
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 52px;
}

.timelines {
  display: flex;
  flex-direction: column;
}

.timelineWrapper {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}

.timelineWrapperActive {
  border-color: #083a55;
}

.timelineWrapper::after {
  position: absolute;
  width: 2px;
  background-color: #e6e6e6;
  height: 27px;
  left: 11px;
  content: "";
}

.timelineWrapperActive::after {
  background-color: #083a55;
}

.timelineWrapperBottom {
  margin-bottom: 25px;
}

.timelineWrapperBottom::after {
  top: 22px;
}

.timelineWrapperActive.timelineWrapperBottom::after {
  top: 26px;
}

.timelineWrapperTop {
  margin-top: 25px;
}

.timelineWrapperTop::after {
  bottom: 22px;
}

.timelineWrapperActive.timelineWrapperTop::after {
  bottom: 26px;
}

.timeline {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  border-radius: 50%;
  color: #ffffff;
  font-family: var(--heading-font-family);
  font-size: 12px;
  font-weight: 300;
}

.timelineWrapperActive .timeline {
  background-color: #083a55;
}

.stepLabel {
  color: #979797;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.stepHeader {
  color: #083a55;
  font-family: var(--heading-font-family);
  font-size: 24px;
  font-weight: bold;
}

.stepFormLabel {
  color: #979797;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

.stepFormInput {
  width: 200px;
}

.previewButton {
  margin-right: 20px;
}

.backButton {
  color: #073a55;
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
  }

  .mainSide {
    grid-row: 1;
    height: 80vh;
  }

  .mainBody {
    padding: 35px 6% 70px;
  }
}
