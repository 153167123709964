.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
}

.mainContent {
  flex-grow: 1;
  padding: 24px 33px 60px;
  max-width: 100%;
}
