.drawer {
  display: flex;
  flex-direction: column;
  max-width: 475px;
  width: 100vw;
  height: 100%;
}

.drawerDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}

.drawerImageButton {
  border: none;
  background-color: #d8d8d8;
  margin-bottom: 10px;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.drawerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.drawerImageLabel {
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ffffff;
  font-family: var(--heading-font-family);
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 6px;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawerImageFileInput {
  display: none;
}

.drawerName {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.drawerEmail {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
}

.drawerFormWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.drawerForm {
  background-color: #f4f6f8;
  border: 1px solid #dfe3e8;
  padding: 30px 20px;
  flex-grow: 1;
}

.drawerFormDivider {
  border-color: #e0e0e0;
}

.drawerFooter {
  padding: 15px 20px 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.drawerFooterButton {
  height: 36px;
}

.drawerFooterSave {
  margin-left: 10px;
}
