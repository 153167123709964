.card {
  cursor: pointer;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cardTitleHeader {
  color: #212b36;
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.cardEllipsis {
  transform: rotate(90deg);
}

.cardIcon {
  width: 20px;
  height: auto;
  margin-bottom: 5px;
}

.cardNumbers {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cardNumbersTotal {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 28px;
  font-weight: bold;
  line-height: 28px;
}

.cardNumbersCohorts {
  color: #979797;
  font-family: var(--heading-font-family);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}
