.modalForm {
  padding: 60px 30px 24px;
  display: flex;
  flex-direction: column;
}

.modalFormRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
}

.modalFormSubmit {
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
}
