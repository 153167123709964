.modal {
  box-shadow: 0 2px 16px 0 rgba(156, 156, 156, 0.15);
  width: 100%;
  max-width: 740px;
  background-color: transparent;
  height: 400px;
}

.modalBody {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  background-color: #f4f6f8;
  min-height: 400px;
  width: 100%;
  padding-left: 25px;
}

.modalBodyText {
  height: 100%;
  border-right: 1px solid #dfe1e5;
  font-family: var(--heading-font-family);
  padding-top: 24px;
}

.modalBodyTextHeader {
  color: #212b36;
  font-size: 20px;
  font-weight: bold;
}

.modalBodyTextContent {
  color: #6b778c;
  font-size: 14px;
  font-weight: 300;
}

.modalForm {
  padding: 60px 30px 30px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modalFormProgram {
  height: 36px;
  width: 100%;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  background-color: #c4cdd5;
  padding-left: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.modalFormProgramText {
  color: #ffffff;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

.modalFormItem {
  margin-bottom: 10px;
}

.modalFormInputAddButton {
  color: #212b36;
  font-family: var(--heading-font-family);
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: none;
  width: fit-content;
}

.modalFormSubmit {
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
}
