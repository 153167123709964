.sideBar {
  display: flex;
  flex-direction: column;
  padding: 30px 0 43px;
  width: 0;
  transition: 300ms;
  background-color: white;
  overflow: hidden;
}

.sideBarActive {
  flex-shrink: 0;
  width: 240px;
}

.sideBarLogout {
  margin-top: auto;
}
